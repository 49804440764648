import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import MDXRenderer from 'gatsby-mdx/mdx-renderer'
import SEO from 'components/SEO'
import { css } from '@emotion/core'
import Container from 'components/Container'
import Layout from '../components/Layout'
import Share from '../components/Share'
import Link from 'components/Link'
import config from '../../config/website'
import { useTheme } from './../components/Theming'
import { bpMaxSM, bpMinMD, bpMinLG } from '../lib/breakpoints'
import get from 'lodash/get'
import './../utils/global.css'

export default function Post({
  data: { site, mdx },
  pageContext: { next, prev },
}) {
  const author = mdx.frontmatter.author || config.author.name
  const date = mdx.frontmatter.date
  const title = mdx.frontmatter.title
  const description = mdx.frontmatter.description
  const banner = mdx.frontmatter.banner
  const github = mdx.fields.github

  const Pre = props =>
                        <div
                            css={{
                                marginBottom: "1.75rem",
                                borderRadius: "10px",
                                background: "#011627",
                                overflow: "auto",
                                color: "#fff",
                            }} 
                        >
                          <pre 
                            css={{
                              padding: "1.3125rem",
                            }}
                            {...props}
                          />
                        </div>
  const components = {
    pre: Pre
  }
  return (
    <Layout site={site} frontmatter={mdx.frontmatter}>
      <SEO
        excerpt={mdx.excerpt}
        postImage={get(banner, 'childImageSharp.fixed.src')}
        frontmatter={mdx.frontmatter}
        isBlogPost
      />
      <article
        css={css({
          width: '100%',
          display: 'flex',
          '.gatsby-resp-image-link': {
            margin: '0 -20px',
            [bpMinMD]: {
              margin: 0,
            },
          },
        })}
      >
        <Container>
          <h1
            css={css({
              textAlign: 'center',
              margin: '0 0 30px 0',
              [bpMinMD]: {
                margin: '0 0 50px 0',
                fontSize: '3rem',
              },
              fontSize: '1.75rem',
            })}
          >
            {title}
          </h1>
          {banner && (
            <div
              css={css({
                margin: '0 -20px 30px -20px',
                [bpMinMD]: {
                  width: '90%',
                  margin: '0 auto 50px auto',
                },
              })}
            >
              <Img
                sizes={banner.childImageSharp.fluid}
                alt={site.siteMetadata.keywords.join(', ')}
              />
            </div>
          )}
          <MDXRenderer>{mdx.code.body}</MDXRenderer>
          {/* <div
            css={css`
              text-align: center;
            `}
          >
            <a href={github} rel="nofollow">
              <strong>
                <em>edit this article on github</em>
              </strong>
            </a>
          </div> */}
        </Container>
        {/* <SubscribeForm /> */}
      </article>
      
      <Container noVerticalPadding>
        <Share
          url={`${config.siteUrl}/${mdx.frontmatter.slug}/`}
          title={title}
          twitterHandle={config.twitterHandle}
        />
        <br />
        <div 
          css={css`
              display: flex;
              justify-content: space-between;
              flex-direction: row;
              flex-wrap: wrap;
          `} 
        >  
          <span
            css={css`
                text-decoration: underline;
            `} 
          >
           { prev && 
            <Link
              to={`/${prev.fields.slug}`}
              aria-label={`Read ${prev.fields.title}`}
            >
             &#8592; {prev.fields.title} 
            </Link>
           }
          </span>
            
          <span 
            css={css`
              text-decoration: underline;
          `} 
          > 
          { next && 
            <Link
              to={`/${next.fields.slug}`}
              aria-label={`Read ${next.fields.title}`}
            >
            {next.fields.title} &#8594;
            </Link>
          }
          </span>
        </div>
      </Container>
      
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    site {
      ...site
    }
    mdx(fields: { id: { eq: $id } }) {
      excerpt(pruneLength: 240)
      fields {
        github
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        banner {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
            fixed(width: 500) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        slug
        keywords
      }
      code {
        body
      }
    }
  }
`
